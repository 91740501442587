import * as React from "react";
import Layout from "../../components/LayoutPlain";
import UserAgreementEN from "content/UserAgreementEN";

const PrivacyPolicyPage = () => {
  return <Layout>
    <UserAgreementEN/>
  </Layout>
}

export default PrivacyPolicyPage;