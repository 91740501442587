import * as React from "react";

const UserAgreementEN = () => {
  return <>
    <ul className={"ordered-text-items"}>
      <h2>User Agreement</h2>
      <li>
        1. Paramla Finansal Danışmanlık A.Ş. (‘Paramla’) has the right to alter or discontinue its services provided on its website and mobile apps.
      </li>
      <li>
        2. Market data is provided by Forinvest Yazılım ve Teknoloji Hizmetleri A.Ş. (Foreks), CoinMarketCap and <a
          href={"https://polygon.io"} target={"_blank"}>Polygon.io</a> Inc.; quoted prices
        are ‘last trade’ prices; BIST and Eurobond prices are quoted with 15 (fifteen) minutes delay.
      </li>
      <li>
        3. Presented current values of financial assets (calculated on ‘last trade’ prices), their trends or profit/loss calculations cannot be understood or interpreted as
        provision of investment consultancy service; such service can only be provided subject to an investment consultancy service agreement signed among the client and legally
        authorized institutions.
      </li>
      <li>
        4. Investment decisions based on presented current values of financial assets, their trends or profit/loss calculations may not provide expected outcome.
      </li>
      <li>
        5. Paramla can by no means be held responsible for direct or indirect loss, loss of profit, intangible loss or any loss by third parties to be incurred due to incorrect or
        incomplete data provided by sources applied in running its web site or mobile apps or any use of data provided on its web site or mobile apps.
      </li>
      <li>
        6. Paramla is the sole owner of the brand, logo, design, graphics, software etc. running on its web site and mobile apps and the intellectual property and copyrights
        attached.
      </li>
      <li>
        7. Paramla can by no means be held responsible for content provided by or actions of third parties advertised on its website or mobile apps.
      </li>
    </ul>
    <ul className={"ordered-text-items"}>
      <h2>Privacy Policy</h2>
      <li>
        1. Personal Identification Data (‘PID’) is personal data provided by one to introduce her/himself like name, surname, e-mail address etc. Paramla neither asks for nor
        stores any PID.
      </li>
      <li>
        2. Paramla has the right to use other data stored on its database for statistical purposes.
      </li>
    </ul>
  </>
}

export default UserAgreementEN;
